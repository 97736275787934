
import {computed, defineComponent, onMounted, ref} from "vue";
import Entities from "@/components/base/common/Entities.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {Actions} from "@/store/enums/StoreEnums";
import {LoadFilterObjects} from "@/core/composite/composite";
import store from "@/store";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import Avatar from "@/components/base/common/Avatar.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";

export default defineComponent({
  name: "LoginAudits",
  components: {DateTimeFormat, Avatar, KtDatatable, Entities},
  setup() {
    onMounted(() => {
      // if (!store.getters.isAdmin) {
      //   router.push({name: "403"});
      // }
      setCurrentPageBreadcrumbsWithParams("Login Audit",
          [
            {link: false, router: '', text: 'Login Audit'}
          ]
      )
    })
    const filterObject = ref({})
    const tableHeader = ref([
      {
        name: "User",
        key: "user",
        sortable: false,
      },
      {
        name: "Time",
        key: "time",
        sortable: false,
      },

      {
        name: "Location",
        key: "location",
        sortable: false,
      },
      /*
            {
              name: "Device",
              key: "device",
              sortable: false,
            },
      */
/*
      {
        name: "IP",
        key: "ip",
        sortable: false,
      },
*/
      {
        name: "Coordinates",
        key: "coordinates",
        sortable: false,
      },
      /*
            {
              name: "Longitude",
              key: "longitude",
              sortable: false,
            },
      */
      /*
            {
              name: "Longitude",
              key: "longitude",
              sortable: false,
            },
      */
      {
        name: "Access",
        key: "accessAble",
        sortable: false,
      },
      {
        name: "User Agent",
        key: "userAgent",
        sortable: false,
      }
    ]);
    const loginPage = computed(() => store.state.LoginModule.loginPage);
    return {
      ...LoadFilterObjects(Actions.LOAD_LOGIN_AUDITS, filterObject.value, ['user']),
      tableHeader,
      loginPage,
    }
  }
})
